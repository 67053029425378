<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <v-row class="mt-0 ml-1" justify="space-between">
          <h5 class="font-weight-bolder text-h5 text-typo">
            {{ unapprovedEvents.length }}
            Ungenehmigte Events
          </h5>
          <div class="mr-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="$store.dispatch('getUnapprovedEvents')"
                  v-bind="attrs"
                  v-on="on"
                  class="material-icons-round text-primary ms-auto"
                  size="30"
                >
                  refresh
                </v-icon>
              </template>
              <span>Aktualisieren</span>
            </v-tooltip>
          </div>
        </v-row>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="unapprovedEvents"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:item.title="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{ item.title }}
              </span>
            </div>
          </template>
          <template v-slot:item.approved="{ item }">
            <div class="d-flex align-center ms-2 text-center">
              <v-icon size="25" class="material-icons-round text-danger"
                >close</v-icon
              >
            </div>
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn
              @click="changeEventApproved(item)"
              :ripple="false"
              small
              text
              width="auto"
              class="text-white font-weight-bolder bg-success mr-5"
            >
              genehmigen
            </v-btn>
            <v-btn
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
              @click="
                $router
                  .push('/admin/administration/events/details/' + item._id)
                  .catch(() => {})
              "
            >
              <v-icon size="25" class="material-icons-round">search</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import administrationData from "@/mixins/administrationMixin";
export default {
  name: "datatable-not-approved",
  mixins: [administrationData],

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      editedIndex: -1,
      editedItemDelete: {},
      editedCommentDelete: {},
      headers: [
        {
          text: "Titel",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "title",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        // {
        //   text: "Aktiv",
        //   value: "active",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "Anmeldungen aktiv",
        //   value: "entries_active",
        //   sortable: false,
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Genehmigungsstatus",
          value: "approved",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Aktionen",
          value: "_id",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getUnapprovedEvents");
  },
  methods: {
    changeEventApproved(event) {
      const values = {
        id: event._id,
        approved: true,
      };
      console.log(values);
      this.$store.dispatch("updateEventStates", values);
    },
  },

  computed: {},
};
</script>
