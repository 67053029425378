<template>
  <div>
    <loading-overlay
      v-if="updatingClients || updatingClientEvents"
      :text="''"
    />

    <v-container fluid class="px-6 py-6">
      <v-row class="mb-6 mt-3 mx-0">
        <v-btn
          variant="outlined"
          v-for="year in getYears"
          :key="year"
          elevation="0"
          height="43"
          :class="
            year == (selectedYear ? selectedYear : currentYear)
              ? 'bg-gradient-primary text-white'
              : ' bg-white'
          "
          class="font-weight-bold text-uppercase py-2 px-6 me-2 mb-sm-0 mb-2"
          :color="'#fff'"
          small
          @click="$store.commit('selectedYear', year)"
          >{{ year }}</v-btn
        >
      </v-row>
      <div></div>
      <v-row class="mb-6 mt-3">
        <v-col cols="12" md="6" lg="4" class="pt-0">
          <ClientCard
            v-if="selectedClient"
            :client="selectedClient"
            :link="false"
            :report="true"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="pt-0">
          <CardClientSalesOverview />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="pt-0">
          <CardClientRevenueOverview />
        </v-col>
      </v-row>
      <p class="mx-3 font-weight-normal text-body" style="font-size: 25px">
        Veranstaltungen {{ selectedYear ? selectedYear : currentYear }}:
      </p>
      <v-row class="mb-6 mt-10">
        <v-col
          v-for="event in clientEvents"
          :key="event._id"
          lg="4"
          class="pt-0 mb-10"
        >
          <event-card
            :event="event"
            :admin="userInformations.superadmin"
          /> </v-col
      ></v-row>
    </v-container>
  </div>
</template>
<script>
import ClientCard from "./Widgets/ClientCard.vue";
import EventCard from "./Widgets/EventCard.vue";
import CardClientSalesOverview from "./Widgets/CardClientSalesOverview.vue";
import CardClientRevenueOverview from "./Widgets/CardClientRevenueOverview.vue";
import administrationData from "@/mixins/administrationMixin";
import coreData from "@/mixins/coreDataMixin";
export default {
  name: "ClientDetails",
  mixins: [administrationData, coreData],
  components: {
    ClientCard,
    EventCard,
    CardClientSalesOverview,
    CardClientRevenueOverview,
  },
  data: function () {
    return {};
  },

  mounted() {
    var thisYear = new Date().getFullYear();
    this.$store.dispatch("getClients");
    this.$store.dispatch("getClientEvents", {
      id: this.$route.params.id,
      year: this.selectedYear ?? thisYear,
    });
  },
  watch: {
    selectedYear() {
      this.$store.dispatch("getClientEvents", {
        id: this.$route.params.id,
        year: this.selectedYear ?? this.thisYear,
      });
    },
  },
  computed: {
    selectedClient() {
      if (this.clients) {
        try {
          return this.clients.find(
            (client) => client._id == this.$route.params.id
          );
        } catch (error) {
          return null;
        }
      } else {
        return "NOPE";
      }
    },
  },
  methods: {},
};
</script>
