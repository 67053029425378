<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          So funktioniert Oelsnitz-Ticket:
        </h2>

        <v-card
          width="100%"
          color="#f6f8fa"
          class="px-5 py-8 my-5"
          style="border-radius: 20px !important"
        >
          <div>
            <v-row class="ma-0 pa-0 justify-content-between">
              <div>
                <v-img
                  height="100%"
                  width="200px"
                  :src="first"
                  class="img-fluid border-radius-lg"
                  :alt="errorImage"
                />
              </div>
              <v-col>
                <div class="mx-3">
                  <h2 class="text-warning text-h1">1.</h2>
                  <h3
                    class="h3 font-weight-normal text-start text-body"
                    style="font-size: 25px"
                  >
                    Veranstaltung aussuchen
                  </h3>
                  <p class="text-body text-start font-weight-light mb-10 mt-5">
                    Wähle eine Veranstaltung von der Homeseite oder der
                    Eventseite aus.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card
          width="100%"
          color="#f6f8fa"
          class="px-5 py-8 my-5"
          style="border-radius: 20px !important"
        >
          <div>
            <v-row class="ma-0 pa-0 justify-content-between">
              <div>
                <v-img
                  height="100%"
                  width="200px"
                  :src="second"
                  class="img-fluid border-radius-lg"
                  :alt="second"
                />
              </div>
              <v-col>
                <div class="mx-3">
                  <h2 class="text-warning text-h1">2.</h2>
                  <h3
                    class="h3 font-weight-normal text-start text-body"
                    style="font-size: 25px"
                  >
                    Auf "Buchen" klicken und das Formular ausfüllen
                  </h3>
                  <p class="text-body text-start font-weight-light mb-10 mt-5">
                    Überprüfe, ob noch genug Tickets verfügbar sind und klicke
                    anschließend auf "Buchen".
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card
          width="100%"
          color="#f6f8fa"
          class="px-5 py-8 my-5"
          style="border-radius: 20px !important"
        >
          <div>
            <v-row class="ma-0 pa-0 justify-content-between">
              <div>
                <v-img
                  height="100%"
                  width="200px"
                  :src="third"
                  class="img-fluid border-radius-lg"
                  :alt="third"
                />
              </div>
              <v-col>
                <div class="mx-3">
                  <h2 class="text-warning text-h1">3.</h2>
                  <h3
                    class="h3 font-weight-normal text-start text-body"
                    style="font-size: 25px"
                  >
                    Tickets bezahlen
                  </h3>
                  <p class="text-body text-start font-weight-light mb-10 mt-5">
                    Bezahle deine Tickets bequem mit PayPal.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card
          width="100%"
          color="#f6f8fa"
          class="px-5 py-8 my-5"
          style="border-radius: 20px !important"
        >
          <div>
            <v-row class="ma-0 pa-0 justify-content-between">
              <div>
                <v-img
                  height="100%"
                  width="200px"
                  :src="fourth"
                  class="img-fluid border-radius-lg"
                  :alt="fourth"
                />
              </div>
              <v-col>
                <div class="mx-3">
                  <h2 class="text-warning text-h1">4.</h2>
                  <h3
                    class="h3 font-weight-normal text-start text-body"
                    style="font-size: 25px"
                  >
                    QR Code bei der Veranstaltung vorzeigen
                  </h3>
                  <p class="text-body text-start font-weight-light mb-10 mt-5">
                    Deine E-Tickets findest du unter: Mein Bereich -> Tickets
                    <br />
                    Wähle die passende Veranstaltung aus und zeige die gebuchten
                    Tickets (QR-Codes) am Einlass vor.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card
          width="100%"
          color="#f6f8fa"
          class="px-5 py-8 my-5"
          style="border-radius: 20px !important"
        >
          <div>
            <v-row class="ma-0 pa-0 justify-content-between">
              <div>
                <v-img
                  height="100%"
                  width="200px"
                  :src="fifth"
                  class="img-fluid border-radius-lg"
                  :alt="fifth"
                />
              </div>
              <v-col>
                <div class="mx-3">
                  <h2 class="text-warning text-h1">5.</h2>
                  <h3
                    class="h3 font-weight-normal text-start text-body"
                    style="font-size: 25px"
                  >
                    Habe Spaß auf der Veranstaltung Deiner Wahl!
                  </h3>
                  <p class="text-body text-start font-weight-light mb-10 mt-5">
                    Noch einfacher geht es mit unserer Smartphone App!
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
    </v-col>
    <v-row class="ma-0 pa-0 justify-center">
      <div class="pt-15" style="max-width: 1400px">
        <appstores-teaser></appstores-teaser>
      </div>
    </v-row>
    <sponsors></sponsors>
    <faq></faq>
  </v-row>
</template>
<script>
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
import AppstoresBanner from "@/components/Widgets/AppstoresBanner.vue";

export default {
  name: "Intro",
  components: {
    Sponsors,
    faq,
    AppstoresBanner,
  },
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
      first: require("@/assets/img/undraw/undraw_Searching_re_3ra9.png"),
      second: require("@/assets/img/undraw/undraw_Choose_re_7d5a.png"),
      third: require("@/assets/img/undraw/undraw_Online_payments_re_y8f2.png"),
      fourth: require("@/assets/img/undraw/undraw_qr.png"),
      fifth: require("@/assets/img/undraw/undraw_Order_confirmed_re_g0if.png"),
    };
  },
};
</script>
